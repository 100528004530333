
import SingleCompanyPost from "./SingleCompanyPost.vue";
export default {
  name: "CompanySection",
  components: {
    SingleCompanyPost,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(100px)";
    },
    enter(el, done) {
      this.$gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 1,
        onComplete: done,
        delay: el.dataset.index * 0.3,
        scrollTrigger: {
          trigger: `.company-${this.block.id}`,
          start: "top 70%",
          end: "bottom",
          scrub: false,
        },
      });
    },
  },
};
