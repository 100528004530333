
import ImageComponent from "./ImageComponent.vue";
export default {
  name: "ImageRed",
  components: {
    ImageComponent,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showImage: false,
    };
  },
  beforeCreate() {
    this.showImage = false;
  },
  mounted() {
    this.showImage = true;
    this.trianglesIn();
    this.squareIn();
  },
  beforeDestroy() {
    this.trianglesOut();
    this.squareOut();
  },
  methods: {
    squareIn() {
      if ([...document.getElementsByClassName("image-red-square")].length > 0) {
        this.$gsap.fromTo(
          `.image-red-square-${this.block.id}`,
          {
            top: 50,
          },
          {
            top: -125,
            scrollTrigger: {
              trigger: `.image-red-${this.block.id}`,
              start: "top 100%",
              end: "bottom -25%",
              scrub: true,
            },
          }
        );
      }
    },
    squareOut() {
      if ([...document.getElementsByClassName("image-red-square")].length > 0) {
        this.$gsap.to(`.image-red-square-${this.block.id}`, {
          opacity: 0,
          top: -225,
        });
      }
    },
    trianglesIn() {
      if (
        [...document.getElementsByClassName("image-red__triangle-pattern")]
          .length > 0
      ) {
        this.$gsap.fromTo(
          `.image-red__triangle-pattern-${this.block.id}`,
          {
            opacity: 0,
            right: 50,
          },
          {
            opacity: 1,
            right: -150,
            scrollTrigger: {
              trigger: `.image-red-${this.block.id}`,
              start: "top 100%",
              end: "bottom -25%",
              scrub: true,
            },
          }
        );
      }
    },
    trianglesOut() {
      if (
        [...document.getElementsByClassName("image-red__triangle-pattern")]
          .length > 0
      ) {
        this.$gsap.to(`.image-red__triangle-pattern-${this.block.id}`, {
          opacity: 0,
          right: -150,
        });
      }
    },
  },
};
