
export default {
  name: "LinkButton",
  props: {
    link: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutableLink: this.link,
    };
  },
  mounted() {
    this.link === "home" ? (this.mutableLink = "") : false;
  },
};
