
import TabletSection from "./TabletSection.vue";
export default {
  name: "FeaturesSlider",
  components: { TabletSection },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sliderSettings: {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
