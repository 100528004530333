import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=324c2fe4&scoped=true"
import script from "./TheFooter.vue?vue&type=script&lang=js"
export * from "./TheFooter.vue?vue&type=script&lang=js"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=324c2fe4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324c2fe4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsletterInput: require('/srv/www/htdocs/techcast-2022-frontend/components/NewsletterInput.vue').default,MenuItem: require('/srv/www/htdocs/techcast-2022-frontend/components/MenuItem.vue').default,TheFooterLegal: require('/srv/www/htdocs/techcast-2022-frontend/components/TheFooterLegal.vue').default,TheFooterSocial: require('/srv/www/htdocs/techcast-2022-frontend/components/TheFooterSocial.vue').default})
