
import TheHeader from "../components/TheHeader.vue";
import TheFooter from "../components/TheFooter.vue";
import ScrollTopButton from "../components/ScrollTopButton.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";

export default {
  components: { TheHeader, TheFooter, ScrollTopButton, LoadingSpinner },
  data() {
    return {
      showHideSpinner: true,
    };
  },
  beforeCreate() {
    this.showHideSpinner = true;
  },
  mounted() {
    this.showHideSpinner = false;
  },
};
