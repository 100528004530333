import { render, staticRenderFns } from "./TwoBoxesSection.vue?vue&type=template&id=64b23b93&scoped=true"
import script from "./TwoBoxesSection.vue?vue&type=script&lang=js"
export * from "./TwoBoxesSection.vue?vue&type=script&lang=js"
import style0 from "./TwoBoxesSection.vue?vue&type=style&index=0&id=64b23b93&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b23b93",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkButton: require('/srv/www/htdocs/techcast-2022-frontend/components/LinkButton.vue').default})
