
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    breakBlockData: {
      type: Object,
    },
  },
  data() {
    return {
      rootUrl: process.env.root_url,
    };
  },
  head() {
    return {
      title: `${this.data.attributes.title && this.data.attributes.title}`,
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content:
            this.data.attributes.page_description &&
            this.data.attributes.page_description,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: `${
            this.data.attributes.title && this.data.attributes.title
          }`,
        },
        {
          hid: "og:desc",
          property: "og:description",
          content:
            this.data.attributes.page_description &&
            this.data.attributes.page_description,
        },
        // twitter card
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: `${
            this.data.attributes.title && this.data.attributes.title
          }`,
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content:
            this.data.attributes.page_description &&
            this.data.attributes.page_description,
        },
      ],
    };
  },
};
