import { render, staticRenderFns } from "./PacketsSection.vue?vue&type=template&id=781e1ce3"
import script from "./PacketsSection.vue?vue&type=script&lang=js"
export * from "./PacketsSection.vue?vue&type=script&lang=js"
import style0 from "./PacketsSection.vue?vue&type=style&index=0&id=781e1ce3&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SinglePacket: require('/srv/www/htdocs/techcast-2022-frontend/components/SinglePacket.vue').default,LinkButton: require('/srv/www/htdocs/techcast-2022-frontend/components/LinkButton.vue').default,MailtoButton: require('/srv/www/htdocs/techcast-2022-frontend/components/MailtoButton.vue').default})
