
import Spinner from "./Spinner.vue";
import DownloadButton from "./DownloadButton.vue";
import ImageComponent from "./ImageComponent.vue";
export default {
  name: "PdfSection",
  components: { Spinner, DownloadButton, ImageComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
};
