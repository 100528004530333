import { render, staticRenderFns } from "./error.vue?vue&type=template&id=d1fa1daa"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroSectionDefault: require('/srv/www/htdocs/techcast-2022-frontend/components/HeroSectionDefault.vue').default,TwoBoxesSection: require('/srv/www/htdocs/techcast-2022-frontend/components/TwoBoxesSection.vue').default,ContactSection: require('/srv/www/htdocs/techcast-2022-frontend/components/ContactSection.vue').default})
