
import VideoComponent from "./VideoComponent.vue";
import ImageComponent from "./ImageComponent.vue";
import LinkButton from "./LinkButton.vue";
import MailtoButton from "./MailtoButton.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "TabletSection",
  components: { LinkButton, ImageComponent, MailtoButton, VideoComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      acceptedYoutubePolicy: this.initAcceptedYoutubePolicyIsChecked(),
      showYoutubeVideos: this.$store.state.acceptedYoutubePolicy,
    };
  },
  watch: {
    "$store.state.acceptedYoutubePolicy": function () {
      this.acceptedYoutubePolicy = this.$store.state.acceptedYoutubePolicy;
      this.showYoutubeVideos = this.$store.state.acceptedYoutubePolicy;
    },
  },
  methods: {
    initAcceptedYoutubePolicyIsChecked: function () {
      if (process.client) {
        const stored = localStorage.getItem("acceptedYoutubePolicy");
        if (stored != "true") {
          // console.log("Nothing stored; default to `false`");
          this.$store.commit("acceptedYoutubePolicy", false);
          this.showYoutubeVideos = false;
          return false;
        } else {
          // console.log("Using stored value " + stored);
          this.$store.commit("acceptedYoutubePolicy", true);
          this.showYoutubeVideos = true;
          return true;
        }
      }
      return false; // Fallback value for SSR
    },
    displayYoutubeVideo(e) {
      this.$store.commit("acceptedYoutubePolicy", e);
      this.showYoutubeVideos = true;
    },
    tabletIn() {
      if (
        [...document.getElementsByClassName("tablet-background__wrapper")]
          .length > 0
      ) {
        this.$gsap.fromTo(
          `.tablet-background__wrapper-${this.block.id}`,
          {
            x: -300,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            duration: 0.8,
            scrollTrigger: {
              trigger: `.tablet-${this.block.id}`,
              start: "top 70%",
            },
          }
        );
      }
    },
    tabletOnScroll() {
      if (
        [...document.getElementsByClassName("tablet-background__wrapper")]
          .length
      ) {
        this.$gsap.to(`.tablet-background__wrapper-${this.block.id}`, {
          y: -70,
          scrollTrigger: {
            trigger: `.tablet-${this.block.id}`,
            start: "top 50%",
            scrub: true,
          },
        });
      }
    },
    tabletSectionOut() {
      if ([...document.getElementsByClassName("tablet")].length > 0) {
        this.$gsap.to(`.tablet-${this.block.id}`, {
          opacity: 0,
          duration: 1.5,
        });
      }
    },
    heroBodyIn() {
      if ([...document.getElementsByClassName("body-animation")].length > 0) {
        this.$gsap.fromTo(
          ".body-animation",
          {
            opacity: 0,
            x: 50,
          },
          {
            opacity: 1,
            x: 0,
          }
        );
      }
    },
    trianglesBottomIn() {
      if (
        [
          ...document.getElementsByClassName(
            `tablet__triangle-pattern__bottom-${this.block.id}`
          ),
        ].length > 0
      ) {
        this.$gsap.from(`.tablet__triangle-pattern__bottom-${this.block.id}`, {
          opacity: 0,
          x: -150,
          scrollTrigger: {
            trigger: `.tablet-${this.block.id}`,
            start: "top 50%",
            end: "bottom -50%",
            scrub: true,
          },
        });
      }
    },
  },
  mounted() {
    this.tabletIn();
    this.tabletOnScroll();
    this.heroBodyIn();
    this.trianglesBottomIn();
    ScrollTrigger.refresh();
  },
  beforeDestroy() {
    this.tabletSectionOut();
  },
};
