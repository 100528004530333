
import ImageComponent from "./ImageComponent.vue";
export default {
  name: "LogosSection",
  components: { ImageComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      interval: function (logo, index) {
        setInterval(() => {
          logo.classList.add("glow");
          setTimeout(() => {
            logo.classList.remove("glow");
          }, 1000);
        }, 2000 + index * 1000);
      },
    };
  },
  methods: {
    changeColorLogo() {
      const logos = [...document.getElementsByClassName("logo")];
      logos.map((logo, index) => {
        this.interval(logo, index);
      });
    },
    clearIntervalLogos() {
      clearInterval(this.interval);
    },
  },
  mounted() {
    this.changeColorLogo();
  },
  beforeDestroy() {
    this.clearIntervalLogos();
  },
};
