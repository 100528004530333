
import ImageComponent from "./ImageComponent.vue";
export default {
  name: "FormImages",
  components: { ImageComponent },
  props: {
    formType: {
      type: String,
    },
  },
  data() {
    return {
      formImageManager: [],
      formImageProduct: [],
      formImageExtra: [],
    };
  },
  async fetch() {
    this.formImageManager = await fetch(
      `${process.env.api_url}/api/form-images-managers?populate=deep`
    )
      .then((res) => res.json())
      .then((data) => data.data[0].attributes.images.data);
    this.formImageProduct = await fetch(
      `${process.env.api_url}/api/form-images-products?populate=deep`
    )
      .then((res) => res.json())
      .then((data) => data.data[0].attributes.images.data);
    this.formImageExtra = await fetch(
      `${process.env.api_url}/api/form-images-extras?populate=deep`
    )
      .then((res) => res.json())
      .then((data) => data.data[0].attributes.images.data);
  },
};
