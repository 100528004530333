import { render, staticRenderFns } from "./HeroSectionHomepage.vue?vue&type=template&id=5f7c1a16&scoped=true"
import script from "./HeroSectionHomepage.vue?vue&type=script&lang=js"
export * from "./HeroSectionHomepage.vue?vue&type=script&lang=js"
import style0 from "./HeroSectionHomepage.vue?vue&type=style&index=0&id=5f7c1a16&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f7c1a16",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextSlider: require('/srv/www/htdocs/techcast-2022-frontend/components/TextSlider.vue').default,LinkButton: require('/srv/www/htdocs/techcast-2022-frontend/components/LinkButton.vue').default,ImageComponent: require('/srv/www/htdocs/techcast-2022-frontend/components/ImageComponent.vue').default})
