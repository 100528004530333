
import ImageComponent from "./ImageComponent.vue";
export default {
  name: "ImagePlayButtons",
  components: {
    ImageComponent,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showImage: false,
    };
  },
  beforeCreate() {
    this.showImage = false;
  },
  mounted() {
    this.showImage = true;
    this.trianglesBottomIn();
  },
  beforeDestroy() {
    this.trianglesBottomOut();
  },
  methods: {
    trianglesBottomIn() {
      if (
        [
          ...document.getElementsByClassName(
            "image-play-buttons__triangle-pattern"
          ),
        ].length > 0
      ) {
        this.$gsap.fromTo(
          `.image-play-buttons__triangle-pattern-${this.block.id}`,
          {
            opacity: 0,
            left: -300,
          },
          {
            opacity: 1,
            left: -75,
            scrollTrigger: {
              trigger: `.image-play-buttons-${this.block.id}`,
              start: "top 100%",
              end: "bottom -25%",
              scrub: true,
            },
          }
        );
      }
    },
    trianglesBottomOut() {
      if (
        [
          ...document.getElementsByClassName(
            "image-play-buttons__triangle-pattern"
          ),
        ].length > 0
      ) {
        this.$gsap.to(`.image-play-buttons__triangle-pattern-${this.block.id}`, {
          opacity: 0,
          left: -75,
        });
      }
    },
  },
};
