
import DownloadButton from "./DownloadButton.vue";
export default {
  name: "SingleCompanyPost",
  components: { DownloadButton },
  props: {
    block: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
};
