
export default {
  name: "SeparatorWhite",
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
};
