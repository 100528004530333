
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "PostImage",
  props: {
    block: {
      type: Object,
    },
  },
  methods: {
    imageIn() {
      if ([...document.getElementsByClassName("post-image-image")].length > 0) {
        this.$gsap.fromTo(
          `.post-image-image-${this.block.id}`,
          {
            y: 100,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: `.post-image-image-${this.block.id}`,
              end: "bottom",
            },
            delay: 0.5,
            duration: 1,
          }
        );
      }
      return;
    },
  },
  mounted() {
    this.imageIn();
    ScrollTrigger.refresh();
  },
};
