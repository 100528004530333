export const state = () => ({
  showNavbar: '',
  currentUrl: '',
  jobs: [],
  acceptedYoutubePolicy: false,
  startAnimationDone: false,
})

export const mutations = {
  showNavbar(state, isDesktop) {
    state.showNavbar = isDesktop
  },
  acceptedYoutubePolicy(state, bool) {
    state.acceptedYoutubePolicy = bool
    localStorage.setItem('acceptedYoutubePolicy', bool)
  },
  startAnimationDone(state, bool) {
    state.startAnimationDone = bool
    localStorage.setItem('startAnimationDone', bool)
  },
  setData(state, { storage, key, data }) {
    Vue.set(storage, key, data)
  },
}
