
import LinkButton from "./LinkButton.vue";
export default {
  name: "PostLink",
  components: { LinkButton },
  props: {
    block: {
      type: Object,
    },
  },
};
