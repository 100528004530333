
import Spinner from "./Spinner.vue";
export default {
  name: "ApplyForm",
  components: Spinner,
  props: {
    block: {
      type: Object,
    },
    currentJob: {
      type: String,
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    return {
      apiUploadsUrl:
        process.env.NODE_ENV === "production"
          ? `${process.env.API_URL}/strapi/admin/plugins/upload?folder=4&sort=createdAt:DESC`
          : "http://localhost:1337/admin/plugins/upload?folder=4&sort=createdAt:DESC",
      min: minDate,
      emailRoute: process.env.email_route,
      uploadRoute: process.env.upload_route,
      emailFrom: process.env.email_from,
      emailTo: process.env.email_to_welcome,
      loading: false,
      success: false,
      error: false,
      form: {
        job: this.currentJob ? this.currentJob : "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        loan: "",
        reason: "",
        cv: null,
        cvPreview: null,
        cvPreviewIcon: false,
        otherFiles: null,
        otherFilesPreview: null,
        otherFilesPreviewIcon: false,
        message: "",
        privacy: "",
        hp: false,
        hp2: null,
        hp3: null,
      },
    };
  },
  methods: {
    onCvUpload(e) {
      this.form.cv =
        e.target.files[0].size > 2000000 ? null : e.target.files[0];
      this.form.cvPreviewIcon = this.form.cv && true;
      this.form.cvPreview = this.form.cv
        ? this.form.cv.name
        : "Datei ist zu groß";
      // console.log("form.cv: ", this.form.cv);
    },
    onOtherFilesUpload(e) {
      this.form.otherFiles =
        e.target.files[0].size > 10000000 ? null : e.target.files[0];
      this.form.otherFilesPreviewIcon = this.form.otherFiles && true;
      this.form.otherFilesPreview = this.form.otherFiles
        ? this.form.otherFiles.name
        : "Datei ist zu groß";
      // console.log("form.cv: ", this.form.otherFilesPreview);
    },
    async formSubmit(e) {
      if (
        this.form.hp !== false ||
        this.form.hp2 !== null ||
        this.form.hp3 !== null
      ) {
        this.form = {
          job: this.currentJob ? this.currentJob : "",
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          loan: "",
          reason: "",
          cv: null,
          cvPreview: null,
          cvPreviewIcon: false,
          otherFiles: null,
          otherFilesPreview: null,
          otherFilesPreviewIcon: false,
          message: "",
          privacy: "",
        };

        alert("Thank you for submission!");
      } else {
        try {
          this.loading = true;
          let formData = new FormData();
          formData.append("files", this.form.cv);
          formData.append("files", this.form.otherFiles);
          const americanDate = this.form.date && this.form.date.split("-");
          const europeanDate =
            americanDate &&
            americanDate[2] + "." + americanDate[1] + "." + americanDate[0];
          var data = {
            subject: "Bewerbungsformular",
            from: this.emailFrom,
            to: this.emailTo,
            html: `
            <div style='padding: 1rem'>
            <h1>Techcast Bewerbungsformular</h1>
            <p></p>
            <p>du hast eine Nachricht von <strong>${this.form.firstname} ${
              this.form.lastname
            }</strong> erhalten.</p>
            <p></p>
            <p><strong>${this.form.firstname} ${
              this.form.lastname
            }</strong> möchte sich um die Stelle als <strong>${
              this.form.job
            }</strong> bewerben.</p>
            <ul>
            <li>Email: <strong>${this.form.email}</strong></li>
            <li>Telefonnummer: <strong>${this.form.phone}</strong></li>
            <li>Frühstmöglicher Eintrittstermin: <strong>${europeanDate}</strong></li>
            <li>Gehaltsvorstellung: <strong>${
              this.form.loan ?? this.form.loan
            }€</strong></li>
            <li>Wodurch ist der Bewerber auf uns aufmerksam geworden?: <strong>${
              this.form.reason ?? this.form.reason
            }</strong></li>
            </ul>
            <p></p>
            ${
              this.form.cv || this.form.otherFiles
                ? `<p>Die Dokumente wurden in die Strapi-Mediathek hochgeladen und befinden sich <strong><a href='${this.apiUploadsUrl}'>HIER</a></strong></p><p></p>`
                : ""
            }
            <ul>
            <li>${this.form.cv ? JSON.stringify(this.form.cv.name) : ""}</li>
            <li>${
              this.form.otherFiles
                ? JSON.stringify(this.form.otherFiles.name)
                : ""
            }</li>
            </ul>
            <p></p>
            <h3>Nachricht:</h3>
            <p>${this.form.message}</p>
            </div>
            `,
          };
          const headers = {
            "Content-Type": "application/json",
          };
          // console.log("FORM: ", this.form);
          // console.log("FORMData: ", formData.values.length);
          this.form.cv || this.form.otherFiles
            ? await this.$axios.$post(this.uploadRoute, formData)
            : null;
          await this.$axios
            .$post(this.emailRoute, JSON.stringify(data), {
              headers,
            })
            .then(
              (result) => {
                this.loading = false;
                this.success = true;
                // console.log("SUCCESS!", result.text);
              },
              (error) => {
                this.loading = false;
                this.error = false;
                // console.log("FAILED...", error.text);
              }
            );
        } catch (error) {
          // console.log({ error });
        }
        // Reset form field
        this.form = {
          job: this.currentJob ? this.currentJob : "",
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          loan: "",
          reason: "",
          cv: null,
          cvPreview: null,
          cvPreviewIcon: false,
          otherFiles: null,
          otherFilesPreview: null,
          otherFilesPreviewIcon: false,
          message: "",
          privacy: "",
        };
      }
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // const day = date.getDate();
      // disable days that fall on the 13th of the month (return day === 13)
      const weekday = date.getDay();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6;
    },
  },
};
