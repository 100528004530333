
export default {
  name: "BreakBlockBoxesSection",
  props: {
    block: {
      type: Object,
      required: true,
    },
  }
};
