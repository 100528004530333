
import ImageComponent from "./ImageComponent.vue";
import LinkButton from "./LinkButton.vue";
import MailtoButton from "./MailtoButton.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "HeaderColor",
  components: { LinkButton, ImageComponent, MailtoButton },
  transition: "default",
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  methods: {
    heroBodyIn() {
      if ([...document.getElementsByClassName("header-color-body")].length) {
        this.$gsap.from(".header-color-body", {
          opacity: 0,
          y: 25,
          duration: 0.8,
        });
      }
    },
    heroBodyOut() {
      if ([...document.getElementsByClassName("header-color-body")].length) {
        this.$gsap.to(".header-color-body", {
          opacity: 0,
          y: 25,
          duration: 0.8,
        });
      }
    },
    extraItemsIn() {
      if ([...document.getElementsByClassName("extra-items")].length) {
        this.$gsap.fromTo(
          ".extra-items",
          {
            opacity: 1,
          },
          {
            opacity: 0,
            x: 300,
            scrollTrigger: {
              trigger: ".header-color",
              start: "top 250px",
              end: "bottom",
              scrub: true,
            },
          }
        );
      }
    },
    extraItemsOut() {
      if ([...document.getElementsByClassName("extra-items")].length) {
        this.$gsap.to(".extra-items", {
          opacity: 0,
          x: 50,
        });
      }
    },
  },
  mounted() {
    this.heroBodyIn();
    this.extraItemsIn();
    ScrollTrigger.refresh();
  },
  beforeDestroy() {
    this.heroBodyOut();
    this.extraItemsOut();
  },
};
