
export default {
  name: "SingleFeatureRow",
  props: {
    block: {
      type: Object,
      required: true,
    },
    feature: {
      type: Object,
      required: true,
    },
  },
};
