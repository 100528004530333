export default function ({ route, redirect }) {
  // Check if the URL contains '/de/'
  if (route.fullPath.includes("/de/")) {
    // Remove the '/de/' from the URL
    const newUrl = route.fullPath.replace("/de/", "/");
    // Redirect the user to the new URL
    return redirect(newUrl);
  } else if (route.fullPath === "/case-studies" || route.fullPath === "/case-studies/") {
    return redirect(
      "/referenzen-beispiele-fuer-erfolgreiche-online-events-und-event-plattformen"
    );
  }
}
