
export default {
  name: "ReadMoreButton",
  props: {
    link: {
      type: String,
    },
    label: {
      type: String,
    },
    isExternLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url: process.env.api_url,
      mutableLink: this.link,
    };
  },
  mounted() {
    this.link === "home" ? (this.mutableLink = "") : false;
  },
};
