
export default {
  name: "ExternLink",
  props: {
    link: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      mutableLink: this.link,
    };
  },
};
