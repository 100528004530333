
import ApplyForm from "./ApplyForm.vue";
import ContactForm from "./ContactForm.vue";
import FormImages from './FormImages.vue';
export default {
  name: "ContactSection",
  components: { ContactForm, ApplyForm, FormImages },
  props: {
    block: {
      type: Object,
      required: true,
    },
    currentJob: {
      type: String,
    },
  },
};
