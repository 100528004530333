
export default {
  name: "MailtoButton",
  props: {
    email: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    subject: {
      type: String,
    },
    withMailIcon: {
      type: Boolean,
      default: false,
    },
  },
};
