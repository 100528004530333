
import ImageComponent from "./ImageComponent.vue";
import ReadMoreButton from "./ReadMoreButton.vue";
export default {
  name: "SingleCaseStudy",
  components: { ReadMoreButton, ImageComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
};
