import { render, staticRenderFns } from "./NewsletterInput.vue?vue&type=template&id=b82ba7c4"
import script from "./NewsletterInput.vue?vue&type=script&lang=js"
export * from "./NewsletterInput.vue?vue&type=script&lang=js"
import style0 from "./NewsletterInput.vue?vue&type=style&index=0&id=b82ba7c4&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spinner: require('/srv/www/htdocs/techcast-2022-frontend/components/Spinner.vue').default})
