
export default {
  name: "PhoneButton",
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
};
