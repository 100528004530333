import { render, staticRenderFns } from "./PresseSection.vue?vue&type=template&id=74fc6750&scoped=true"
import script from "./PresseSection.vue?vue&type=script&lang=js"
export * from "./PresseSection.vue?vue&type=script&lang=js"
import style0 from "./PresseSection.vue?vue&type=style&index=0&id=74fc6750&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74fc6750",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SinglePresseBox: require('/srv/www/htdocs/techcast-2022-frontend/components/SinglePresseBox.vue').default})
