
import ImageComponent from "./ImageComponent.vue";

export default {
  name: "ImageSection",
  components: { ImageComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showImage: false,
    };
  },
  beforeCreate() {
    this.showImage = false;
  },
  mounted() {
    this.showImage = true;
  },
};
