
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "ImageTeam",
  props: {
    member: {
      type: Object,
      required: true,
    },
    member_id: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    // this.trianglesIn();
    // this.squareIn();
    // ScrollTrigger.refresh();
  },
  beforeDestroy() {
    // this.trianglesOut();
    // this.squareOut();
  },
  methods: {
    getEveryFourItems(id) {
      if (id === 1 || id === 1 + 4 || id === 1 + 4 + 4) {
        return "item-first";
      } else if (id === 2 || id === 2 + 4 || id === 2 + 4 + 4) {
        return "item-second";
      } else if (id === 3 || id === 3 + 4 || id === 3 + 4 + 4) {
        return "item-third";
      } else if (id === 4 || id === 4 + 4 || id === 4 + 4 + 4) {
        return "item-fourth";
      }
    },
    squareIn() {
      if (
        [...document.getElementsByClassName("image-team__red-square")].length >
        0
      ) {
        this.$gsap.from(`.image-team__red-square-${this.member_id}`, {
          rotationY: 90,
          scrollTrigger: {
            trigger: `.image-team__image-wrapper-${this.member_id}`,
            start: "top 100%",
            end: "bottom",
            scrub: true,
          },
        });
      }
    },
    squareOut() {
      if (
        [...document.getElementsByClassName("image-team__red-square")].length >
        0
      ) {
        this.$gsap.to(`.image-team__red-square-${this.member_id}`, {
          rotationY: 90,
        });
      }
    },
    trianglesIn() {
      if (
        [...document.getElementsByClassName("image-team__triangle-pattern")]
          .length > 0
      ) {
        this.$gsap.from(`.image-team__triangle-pattern-${this.member_id}`, {
          opacity: 1,
          scale: 0,
          scrollTrigger: {
            trigger: `.image-team__image-wrapper-${this.member_id}`,
            start: "top 100%",
            end: "bottom",
            scrub: true,
          },
        });
      }
    },
    trianglesOut() {
      if (
        [...document.getElementsByClassName("image-team__triangle-pattern")]
          .length > 0
      ) {
        this.$gsap.to(`.image-team__triangle-pattern-${this.member_id}`, {
          opacity: 1,
          scale: 0,
        });
      }
    },
  },
};
