import { render, staticRenderFns } from "./SinglePacket.vue?vue&type=template&id=6ef00818"
import script from "./SinglePacket.vue?vue&type=script&lang=js"
export * from "./SinglePacket.vue?vue&type=script&lang=js"
import style0 from "./SinglePacket.vue?vue&type=style&index=0&id=6ef00818&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MailtoButton: require('/srv/www/htdocs/techcast-2022-frontend/components/MailtoButton.vue').default})
