
import ImageComponent from "./ImageComponent.vue";
import LinkButton from "./LinkButton.vue";
import MailtoButton from "./MailtoButton.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "HeroSectionDefault",
  components: { LinkButton, ImageComponent, MailtoButton },
  transition: "default",
  props: {
    block: {
      type: Object,
      required: true,
    },
    withoutImages: {
      type: Boolean,
    },
  },
  methods: {
    laptopImageIn() {
      if (
        [...document.getElementsByClassName("laptop-background__wrapper")]
          .length
      ) {
        this.$gsap.from(".laptop-background__wrapper", {
          opacity: 0,
          x: 250,
          duration: 1,
        });
      }
    },
    laptopImageOut() {
      if (
        [...document.getElementsByClassName("laptop-background__wrapper")]
          .length
      ) {
        this.$gsap.to(".laptop-background__wrapper", {
          opacity: 0,
          x: 250,
          duration: 1,
        });
      }
    },
    heroBodyIn() {
      if ([...document.getElementsByClassName("hero-body")].length) {
        this.$gsap.from(".hero-body", {
          opacity: 0,
          y: 25,
          duration: 0.8,
        });
      }
    },
    heroBodyOut() {
      if ([...document.getElementsByClassName("hero-body")].length) {
        this.$gsap.to(".hero-body", {
          opacity: 0,
          y: 25,
          duration: 0.8,
        });
      }
    },
    greenItemIn() {
      if ([...document.getElementsByClassName("green-item")].length) {
        this.$gsap.fromTo(
          `.green-item-${this.block.id}`,
          {
            opacity: 0,
            x: 350,
          },
          {
            opacity: 1,
            x: 0,
            duration: 1.2,
          }
        );
      }
    },
    greenItemOut() {
      if ([...document.getElementsByClassName("green-item")].length) {
        this.$gsap.to(`.green-item-${this.block.id}`, {
          opacity: 0,
          x: 350,
          duration: 1.2,
          delay: 0.2,
        });
      }
    },
    redItemIn() {
      if ([...document.getElementsByClassName("red-item")].length) {
        this.$gsap.fromTo(
          `.red-item-${this.block.id}`,
          {
            opacity: 0,
            x: 350,
          },
          { opacity: 1, x: 0, duration: 1.5 }
        );
      }
    },
    redItemOut() {
      if ([...document.getElementsByClassName("red-item")].length) {
        this.$gsap.to(`.red-item-${this.block.id}`, {
          opacity: 0,
          x: 350,
          duration: 1.5,
          delay: 0.5,
        });
      }
    },
    extraItemsIn() {
      if ([...document.getElementsByClassName("extra-items")].length) {
        this.$gsap.fromTo(
          ".extra-items",
          {
            opacity: 1,
            x: -70,
          },
          {
            opacity: 0,
            x: 100,
            scrollTrigger: {
              trigger: ".hero",
              start: "top 250px",
              end: "bottom",
              scrub: true,
            },
          }
        );
      }
    },
    extraItemsOut() {
      if ([...document.getElementsByClassName("extra-items")].length) {
        this.$gsap.to(".extra-items", {
          opacity: 0,
          x: 50,
        });
      }
    },
    colouredBoxIn() {
      if ([...document.getElementsByClassName("coloured-box")].length) {
        this.$gsap.from(".coloured-box", {
          opacity: 0.25,
        });
      }
    },
    colouredBoxOut() {
      if ([...document.getElementsByClassName("coloured-box")].length) {
        this.$gsap.to(".coloured-box", {
          opacity: 0.25,
        });
      }
    },
  },
  mounted() {
    this.laptopImageIn();
    // this.heroBodyIn();
    this.colouredBoxIn();
    this.extraItemsIn();
    this.greenItemIn();
    this.redItemIn();
    ScrollTrigger.refresh();
  },
  beforeDestroy() {
    this.laptopImageOut();
    // this.heroBodyOut();
    this.colouredBoxOut();
    this.extraItemsOut();
    this.greenItemOut();
    this.redItemOut();
  },
};
