// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/grey-dots.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".packets{margin-bottom:7rem}.packets .background-dots{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat;background-size:8rem;height:90%;left:0;position:absolute;top:-4rem;width:100%;z-index:-1}@media(min-width:768px){.packets .background-dots{background-size:13rem}}.packets .outter-container{background-color:#fff;margin:0 auto;padding:3rem 15px;width:calc(100% - 30px)}@media(min-width:768px){.packets .outter-container{padding:3rem 2rem;width:90%}}.packets .cta-bottom{background-color:var(--lightgrey-price-box);padding:2.5rem}@media(min-width:768px){.packets .cta-bottom h3{font-size:32px}.packets .cta-bottom p{font-size:20px}}.packets .cta-bottom .button{border:none;font-family:var(--font-bold);text-transform:capitalize}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
