import Vue from 'vue'
import VueMatomo from 'vue-matomo'

export default ({ app }) => {
  Vue.use(VueMatomo, {
    router: app.router,
    host: process.env.NODE_ENV === "production" ? process.env.MATOMO_HOST : "http://matomo.localhost",
    // trackerUrl: process.env.NODE_ENV === "production" ? 'https://www.techcast.com/stats/matomo.php' : '//matomo.localhost/matomo.php',
    siteId: process.env.NODE_ENV === "production" ? process.env.MATOMO_ID : 1,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: true,
    requireCookieConsent: false,
  })
}