import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dffbdcd2 = () => interopDefault(import('../pages/case-studies/index.vue' /* webpackChunkName: "pages/case-studies/index" */))
const _1191adb2 = () => interopDefault(import('../pages/karriere/index.vue' /* webpackChunkName: "pages/karriere/index" */))
const _3095cde8 = () => interopDefault(import('../pages/kontaktformular-vielen-dank/index.vue' /* webpackChunkName: "pages/kontaktformular-vielen-dank/index" */))
const _5857df0f = () => interopDefault(import('../pages/presse/index.vue' /* webpackChunkName: "pages/presse/index" */))
const _66e32131 = () => interopDefault(import('../pages/preview/index.vue' /* webpackChunkName: "pages/preview/index" */))
const _ce46e6c2 = () => interopDefault(import('../pages/wissenswertes/index.vue' /* webpackChunkName: "pages/wissenswertes/index" */))
const _46527e90 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _682a924b = () => interopDefault(import('../pages/case-studies/preview/_slug/index.vue' /* webpackChunkName: "pages/case-studies/preview/_slug/index" */))
const _9b4539b4 = () => interopDefault(import('../pages/karriere/preview/_slug/index.vue' /* webpackChunkName: "pages/karriere/preview/_slug/index" */))
const _4aaf6dc3 = () => interopDefault(import('../pages/presse/preview/_slug/index.vue' /* webpackChunkName: "pages/presse/preview/_slug/index" */))
const _266d1f53 = () => interopDefault(import('../pages/wissenswertes/preview/_slug/index.vue' /* webpackChunkName: "pages/wissenswertes/preview/_slug/index" */))
const _ea5f80dc = () => interopDefault(import('../pages/case-studies/_slug/index.vue' /* webpackChunkName: "pages/case-studies/_slug/index" */))
const _45831b6d = () => interopDefault(import('../pages/karriere/_slug/index.vue' /* webpackChunkName: "pages/karriere/_slug/index" */))
const _28c4130a = () => interopDefault(import('../pages/presse/_slug/index.vue' /* webpackChunkName: "pages/presse/_slug/index" */))
const _44e9b3ac = () => interopDefault(import('../pages/preview/_slug/index.vue' /* webpackChunkName: "pages/preview/_slug/index" */))
const _4dd4549a = () => interopDefault(import('../pages/wissenswertes/_slug/index.vue' /* webpackChunkName: "pages/wissenswertes/_slug/index" */))
const _1cd351f3 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/case-studies",
    component: _dffbdcd2,
    name: "case-studies"
  }, {
    path: "/karriere",
    component: _1191adb2,
    name: "karriere"
  }, {
    path: "/kontaktformular-vielen-dank",
    component: _3095cde8,
    name: "kontaktformular-vielen-dank"
  }, {
    path: "/presse",
    component: _5857df0f,
    name: "presse"
  }, {
    path: "/preview",
    component: _66e32131,
    name: "preview"
  }, {
    path: "/wissenswertes",
    component: _ce46e6c2,
    name: "wissenswertes"
  }, {
    path: "/",
    component: _46527e90,
    name: "index"
  }, {
    path: "/case-studies/preview/:slug",
    component: _682a924b,
    name: "case-studies-preview-slug"
  }, {
    path: "/karriere/preview/:slug",
    component: _9b4539b4,
    name: "karriere-preview-slug"
  }, {
    path: "/presse/preview/:slug",
    component: _4aaf6dc3,
    name: "presse-preview-slug"
  }, {
    path: "/wissenswertes/preview/:slug",
    component: _266d1f53,
    name: "wissenswertes-preview-slug"
  }, {
    path: "/case-studies/:slug",
    component: _ea5f80dc,
    name: "case-studies-slug"
  }, {
    path: "/karriere/:slug",
    component: _45831b6d,
    name: "karriere-slug"
  }, {
    path: "/presse/:slug",
    component: _28c4130a,
    name: "presse-slug"
  }, {
    path: "/preview/:slug",
    component: _44e9b3ac,
    name: "preview-slug"
  }, {
    path: "/wissenswertes/:slug",
    component: _4dd4549a,
    name: "wissenswertes-slug"
  }, {
    path: "/:slug",
    component: _1cd351f3,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
