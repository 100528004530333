// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/grey-dots.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".team .background-dots[data-v-51076c00]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat;background-size:8rem;height:70%;left:0;position:absolute;top:-4rem;width:100%;z-index:-1}@media(min-width:768px){.team .background-dots[data-v-51076c00]{background-size:13rem}}.team .outter-container[data-v-51076c00]{margin:0 auto;width:calc(100% - 30px)}.team .outter-container .container[data-v-51076c00]{background-color:#fff;padding:3rem 15px}@media(min-width:768px){.team .outter-container .container[data-v-51076c00]{padding:3rem 2rem;width:90%}}.team .outter-container .container .member--wrapper[data-v-51076c00]{border-top:2px solid var(--lightgrey-price-box);padding-bottom:3rem;padding-top:4rem}.team .outter-container .container .member:last-of-type .member--wrapper[data-v-51076c00]{border-bottom:2px solid var(--lightgrey-price-box)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
