
export default {
  name: "MenuItem",
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
    classes: {
      type: String,
    },
  },
};
