
import Spinner from "./Spinner.vue";
export default {
  name: "ContactEmail",
  components: { Spinner },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentUrl: process.env.api_url + this.$nuxt.$route.path,
      url: process.env.api_url,
      emailRoute: process.env.email_route,
      emailFrom: process.env.email_from,
      emailTo: process.env.email_to_info,
      loading: false,
      success: false,
      error: false,
      form: {
        email: "",
        privacy: "",
        hp: false,
        hp2: null,
        hp3: null,
      },
    };
  },
  methods: {
    async formSubmit(e) {
      if (
        this.form.hp !== false ||
        this.form.hp2 !== null ||
        this.form.hp3 !== null
      ) {
        this.form = {
          email: "",
          privacy: "",
        };

        alert("Thank you for submission!");
      } else {
        try {
          // console.log("FORM: ", this.form);
          // activate loading animation during the POST request
          this.loading = true;

          // insert all values in the var data that will be sent in the email
          var emailMessage = {
            subject: "Techcast Kontakt-/Demo-Anfrage",
            from: this.emailFrom,
            to: this.emailTo,
            html: `<div style='padding: 1rem'>
            <h1>Techcast Kontakt-/Demo-Anfrage</h1>
            <p></p>
            <p>Das Techcast-Kontaktformular wurde auf dieser Seite ausgefüllt: <strong>${this.currentUrl}</strong></p>
            <p></p>
            <p>Ein Interessent hat das Techcast-Kontaktformular ausgefüllt:</p>
            <p></p>
            <p><strong>${this.form.email}</strong></p>
            <p></p>
            <p>Bitte melde dich unter dieser Mail-Adresse und vereinbare einen (Demo)-Termin.</p>
            <p></p>
            </div>
            `,
          };

          // axios request headers
          const emailHeaders = {
            "Content-Type": "application/json",
          };

          // axios POST request to send the email to marketing department
          await this.$axios
            .$post(this.emailRoute, JSON.stringify(emailMessage), {
              headers: emailHeaders,
            })
            .then(
              (result) => {
                this.loading = false;
                this.success = true;
                // console.log("SUCCESS! warning email", result.text);
              },
              (error) => {
                // console.log("FAILED warning email...", error.text);
              }
            );
        } catch (error) {
          // console.log({ error });
        }
        // Reset form field
        this.form = {
          email: "",
          privacy: "",
        };
      }
    },
  },
};
