import { render, staticRenderFns } from "./TeamSection.vue?vue&type=template&id=51076c00&scoped=true"
import script from "./TeamSection.vue?vue&type=script&lang=js"
export * from "./TeamSection.vue?vue&type=script&lang=js"
import style0 from "./TeamSection.vue?vue&type=style&index=0&id=51076c00&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51076c00",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TeamMember: require('/srv/www/htdocs/techcast-2022-frontend/components/TeamMember.vue').default,ImageTeam: require('/srv/www/htdocs/techcast-2022-frontend/components/ImageTeam.vue').default})
