import { render, staticRenderFns } from "./ArchiveSection.vue?vue&type=template&id=32b63ab8&scoped=true"
import script from "./ArchiveSection.vue?vue&type=script&lang=js"
export * from "./ArchiveSection.vue?vue&type=script&lang=js"
import style0 from "./ArchiveSection.vue?vue&type=style&index=0&id=32b63ab8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b63ab8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SingleArchivePost: require('/srv/www/htdocs/techcast-2022-frontend/components/SingleArchivePost.vue').default})
