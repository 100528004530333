import { render, staticRenderFns } from "./SinglePresseBox.vue?vue&type=template&id=bf619d9c&scoped=true"
import script from "./SinglePresseBox.vue?vue&type=script&lang=js"
export * from "./SinglePresseBox.vue?vue&type=script&lang=js"
import style0 from "./SinglePresseBox.vue?vue&type=style&index=0&id=bf619d9c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf619d9c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReadMoreButton: require('/srv/www/htdocs/techcast-2022-frontend/components/ReadMoreButton.vue').default})
